import React from "react";

//components
import SubscriptionPlanForm from "../components/SubscriptionPlanForm";

const NewSubscriptionPlan = () => {
  return <SubscriptionPlanForm />;
};

export default NewSubscriptionPlan;
